import Vue, { VueConstructor } from 'vue';
import {
  VRow,
  VCol,
  VCard,
  VCardText,
  VCardTitle,
  VSkeletonLoader,
  VBtn,
  VDataTable,
  VIcon,
  VTooltip,
} from 'vuetify/lib';
import { unixToDateTimeUTCString, iso8601ToDateTimeUTCString } from '@/utils/date';
import { RouterLink } from '@/router';
import { formatCurrency, currencyAlias, round } from '@/filters';
import currenciesConfig from '@/config/currencies';
import { financeValueClass } from '@/utils/financeValueClass';
import CountryFlag from 'vue-country-flag';
import './BetDetails.less';
import {
  betQueryFields,
  LEDGER_QUERY,
  BET_STATE_HISTORY_QUERY,
  BET_QUERY,
  LedgerRecord,
  BetStateHistory,
  SportsBet,
  CashoutRef,
  PROVIDER_BETS_QUERY,
  ProviderBet,
} from './queries';
import CancelDialog from '../PlayerBets/CancelDialog';
import { hasRoles } from '@/utils/auth';
import BetLogs, { Provider } from './BetLogs';
import BetSelections from './BetSelections';
import errorHandlerMixin from '../errorHandlerMixin';
import { cancelActionPermitted, reconfirmActionPermitted } from './helpers';
import ReconfirmDialog from '../PlayerBets/ReconfirmDialog';

const formatAmount = (amount: number, currency: string) => {
  return formatCurrency(amount, currenciesConfig[currency]?.precise?.dp || 8);
};

const formatEuroAmount = (amount: number, exchangeRate: number) => {
  const amountInEuro = amount * exchangeRate;
  return formatCurrency(
    amountInEuro,
    currenciesConfig.EUR?.simple?.dp || 2, { name: 'EUR' });
};

const financePermitted = () => {
  return hasRoles(['finance:user', 'finance:operator', 'sports:operator']);
};

const potentialReturnAmountStates = ['pending', 'rejected', 'cancelled'];

export default (Vue as VueConstructor<Vue & InstanceType<typeof errorHandlerMixin>>).extend({
  mixins: [errorHandlerMixin],
  props: {
    betUUID: String, // can be id or uuid
  },

  data() {
    return {
      bet: {} as SportsBet,
      ledgerRecords: [] as LedgerRecord[],
      betStateHistoryList: [] as BetStateHistory[],
      providerBetsQuery: [] as ProviderBet[],
      showCancelDialog: false,
      showReconfirmDialog: false,
      showLogs: Object.values<string>(Provider).reduce((map: any, p) => {
        map[p] = false;
        return map;
      }, {}),
      showUserAgent: false,
    };
  },

  methods: {
    onBetChanged(bet: SportsBet) {
      const data = this.$apollo.getClient().readQuery({
        query: BET_QUERY,
        variables: { uuid: this.betUUID },
      });

      this.$apollo.getClient().writeQuery({
        query: BET_QUERY,
        variables: { uuid: this.betUUID },
        data: { ...data, sportsBet: bet },
      });

      this.$apollo.queries.ledgerQuery.refresh();
      this.$apollo.queries.betStateHistoryQuery.refresh();
    },

    onBetCancelled(cancelResult: Array<[SportsBet | null, Error | null]>) {
      this.showCancelDialog = false;

      const [bet, err] = cancelResult[0];

      if (bet) {
        this.onBetChanged(bet);
      } else if (err) {
        this.showFailureMessage(['Bet has not been cancelled:', err.message]);
      }
    },

    logsView(provider: string) {
      if (this.showLogs[provider]) {
        return (
          <BetLogs
            betSlipId={this.bet.betSlipId}
            provider={provider}
            onClose={() => this.showLogs[provider] = false} />
        );
      }
    },
  },

  computed: {
    selections() {
      return (
        <BetSelections betUUID={this.bet.uuid}
          returnBetFields={betQueryFields}
          translations={this.bet.translations}
          onChanged={this.onBetChanged} />
      );
    },

    betStateHistory() {
      if (this.$apollo.queries.betStateHistoryQuery.loading) {
        return (<VSkeletonLoader type='card-heading, table-row-divider@2' />);
      }

      return (
        <VCard elevation={3} class='mb-1'>
          <VCardTitle>Bet State History</VCardTitle>
          <VCardText>
            <VDataTable
              class='elevation-1'
              headers={[
                { text: 'Updated At', value: 'timestamp' },
                { text: 'State', value: 'state' },
                { text: 'Result', value: 'result' },
                { text: 'Return Amount', value: 'returnAmount' },
                { text: 'Graded By', value: 'gradedBy' }]}
              items={this.betStateHistoryList || []}
              loading-text='Loading... Please wait'
              loading={this.$apollo.queries.betStateHistoryQuery.loading}
              disablePagination={true}
              disableSort={true}
              hideDefaultFooter={true}
              scopedSlots={{
                'item.timestamp': ({ item }: { item: BetStateHistory }) => {
                  return iso8601ToDateTimeUTCString(item.timestamp);
                },
              }}>
            </VDataTable>
          </VCardText>
        </VCard>
      );
    },

    providerBets() {
      if (this.$apollo.queries.providerBetsQuery.loading) {
        return (<VSkeletonLoader type='card-heading, table-row-divider@2' />);
      }

      return (
        <VCard elevation={3} class='mb-1'>
          <VCardTitle>Provider Bets</VCardTitle>
          <VCardText>
            <VDataTable
              class='elevation-1'
              headers={[
                { text: 'Provider', value: 'betType' },
                { text: 'Stake', value: 'stake' },
                { text: 'Currency', value: 'currency' },
                { text: 'Odds', value: 'placedOdds' },
                { text: 'Return amount', value: 'returnAmount' },
                { text: 'Result', value: 'result' },
                { text: 'State', value: 'state' },
              ]}
              items={this.providerBetsQuery || []}
              loading-text='Loading... Please wait'
              loading={this.$apollo.queries.providerBetsQuery.loading}
              disablePagination={true}
              disableSort={true}
              hideDefaultFooter={true}
              noDataText='There are no providers bets'
              scopedSlots={{
                'item.stake': ({ item }: { item: ProviderBet }) => {
                  return formatAmount(Number(item.stake), item.currency);
                },
                'item.returnAmount': ({ item }: { item: ProviderBet }) => {
                  if (item.result === 'PENDING') {
                    return formatAmount(Number(item.potentialReturnAmount), item.currency);
                  } else {
                    return formatAmount(Number(item.returnAmount), item.currency);
                  }
                },
              }}>
            </VDataTable>
          </VCardText>
        </VCard>
      );
    },

    cashoutRefs() {
      if (this.$apollo.queries.betQuery.loading) {
        return (<VSkeletonLoader type='card-heading, table-row-divider@2' />);
      }

      const items = (this.bet.cashouts?.length > 0 ? this.bet.cashouts : this.bet.cashedOutBets) || [];
      if (items.length === 0) {
        return;
      }

      const title = this.bet.cashouts?.length > 0 ? 'Cashouts' : 'Cashed Out Bets';

      return (
        <VCard elevation={3} class='mb-1'>
          <VCardTitle>{title}</VCardTitle>
          <VCardText>
            <VDataTable
              class='elevation-1'
              headers={[
                { text: title, value: 'uuid' },
                {
                  text: 'Exhausted Potential Return Amount',
                  value: 'exhaustedPotentialReturnAmount',
                  width: '300px',
                }]}
              items={items}
              loading-text='Loading... Please wait'
              loading={this.$apollo.queries.betQuery.loading}
              disablePagination={true}
              disableSort={true}
              hideDefaultFooter={true}
              scopedSlots={{
                'item.uuid': ({ item }: { item: CashoutRef }) => {
                  return (
                    <RouterLink
                      class='text-decoration-none'
                      to={{ name: 'sportsBetDetails', params: { betId: item.uuid } }}>
                      {item.uuid}
                    </RouterLink>
                  );
                },
                'item.exhaustedPotentialReturnAmount': ({ item }: { item: CashoutRef }) => {
                  return (
                    <div>
                      {formatEuroAmount(item.exhaustedPotentialReturnAmount, this.bet.exchangeRate)}&nbsp;
                      <small class='grey--text'>
                        {formatAmount(item.exhaustedPotentialReturnAmount, this.bet.currency)} {this.bet.currency}
                      </small>
                    </div>
                  );
                },
              }}>
            </VDataTable>
          </VCardText>
        </VCard>
      );
    },

    walletTransactions() {
      if (this.$apollo.queries.ledgerQuery.loading) {
        return (<VSkeletonLoader type='card-heading, table-row-divider@2' />);
      }

      return (
        <VCard elevation={3} class='mb-1'>
          <VCardTitle>Wallet transactions</VCardTitle>
          <VCardText>
            <VDataTable
              class='elevation-1'
              headers={[
                { text: 'Amount', value: 'amount' },
                { text: 'Currency', value: 'currency' },
                { text: 'Description', value: 'txEvent' },
                { text: 'Client transaction id', value: 'txId' },
                { text: 'Created At', value: 'timestamp' }]}
              items={this.ledgerRecords || []}
              loading-text='Loading... Please wait'
              loading={this.$apollo.queries.ledgerQuery.loading}
              disablePagination={true}
              sortBy='timestamp'
              hideDefaultFooter={true}
              scopedSlots={{
                'item.amount': ({ item }: { item: LedgerRecord }) => {
                  const amount = item.creditAccountType === 'USER' ? item.amount : -item.amount;
                  return formatAmount(amount, item.currency);
                },
                'item.currency': ({ item }: { item: LedgerRecord }) => {
                  return currencyAlias(item.currency);
                },
                'item.timestamp': ({ item }: { item: LedgerRecord }) => {
                  return unixToDateTimeUTCString(Date.parse(item.timestamp) / 1000);
                },
              }}>
            </VDataTable>
          </VCardText>
        </VCard>
      );
    },

    revenue() {
      const revenue = 0 - this.bet.returnAmount;
      if (revenue !== 0) {
        const className = financeValueClass(revenue);
        return <span class={className}>{formatEuroAmount(revenue, this.bet.exchangeRate)}</span>;
      }
    },

    stake() {
      if (this.bet.stake !== 0) {
        return (
          <div>
            <div>
              {formatEuroAmount(this.bet.stake, this.bet.exchangeRate)}
            </div>
            <div>
              <small class='grey--text'>
                {formatAmount(this.bet.stake, this.bet.currency)} {this.bet.currency}
              </small>
            </div>
          </div>
        );
      }
    },

    returnAmount() {
      if (potentialReturnAmountStates.includes(this.bet.state)) {
        if (this.bet.potentialReturnAmount !== 0) {
          return (
            <div>
              <div>{formatEuroAmount(this.bet.potentialReturnAmount, this.bet.exchangeRate)}</div>
              <div>
                <small class='grey--text'>
                  {formatAmount(this.bet.potentialReturnAmount, this.bet.currency)} {this.bet.currency}
                </small>
              </div>
              <div>{this.cashedOut}</div>
            </div>
          );
        }
      } else {
        if (this.bet.returnAmount !== 0) {
          return (
            <div>
              <div>{formatEuroAmount(this.bet.returnAmount, this.bet.exchangeRate)}</div>
              <div>
                <small class='grey--text'>
                  {formatAmount(this.bet.returnAmount, this.bet.currency)} {this.bet.currency}
                </small>
              </div>
              <div>{this.cashedOut}</div>
            </div>
          );
        }
      }
    },

    returnAmountLabel() {
      if (potentialReturnAmountStates.includes(this.bet.state)) {
        return 'Potential return amount';
      } else {
        return 'Return amount';
      }
    },

    cashedOut() {
      if (this.bet.exhaustedPotentialReturnAmount !== 0) {
        return (
          <small class='grey--text'>
            {round(this.bet.exhaustedPotentialReturnAmount * 100 / this.bet.potentialReturnAmount, 0)}%
          &nbsp;cashed out
          </small>
        );
      }
    },

    titleCard() {
      return (
        <VCard elevation={3} class='mb-1'>
          <VCardTitle>{this.pageTitle}</VCardTitle>
          <VCardText>
            <VRow dense>
              <VCol md='1' cols='3' class='text-right'>
                <strong class='text-uppercase'>Player</strong>
              </VCol>
              <VCol md='3' cols='9'>
                <RouterLink
                  class='text-decoration-none'
                  to={{ name: 'player', params: { playerUUID: this.bet.playerUUID } }}>
                  {this.bet.playerUUID}
                </RouterLink>
              </VCol>
              <VCol md='8' cols='12' class='text-right'>
                {Object.values<string>(Provider).map((p) => {
                  if (!this.bet.selections.map((s) => {
                    return JSON.parse(s.hedgingProviders)[0]?.provider?.toLowerCase() || s.externalProvider;
                  }).includes(p)) {
                    return null;
                  }

                  return <span class='mr-2'>
                    <VBtn color='primary' onClick={() => this.showLogs[p] = true} >{p} logs</VBtn>
                  </span>;
                })}
                {cancelActionPermitted(this.bet) &&
                  <span>
                    <VBtn color='warning' onClick={() => this.showCancelDialog = true} >Cancel</VBtn>
                  </span>}
                {reconfirmActionPermitted(this.bet) &&
                  <span class='ml-2'>
                    <VBtn color='warning' onClick={() => this.showReconfirmDialog = true} >Reconfirm</VBtn>
                  </span>}
              </VCol>
            </VRow>
            {(this.bet.device || this.bet.userAgent) &&
              <VRow dense>
                <VCol md='1' cols='3' class='text-right'>
                  <strong class='text-uppercase'>Device</strong>
                </VCol>
                <VCol md='3' cols='9'>
                  {this.bet.userAgent ?
                    <div>
                      <a class='filters-toggle' onClick={() => { this.showUserAgent = !this.showUserAgent; }}>
                        {this.bet.device || 'unknown'}
                      </a> <span class='grey--text'>(click to see the user agent)</span>
                    </div>
                    :
                    <span>{this.bet.device}</span>
                  }
                </VCol>
              </VRow>
            }
            {this.showUserAgent &&
              <VRow dense>
                <VCol md='11' offset-md='1' cols='12'>
                  {this.bet.userAgent}
                </VCol>
              </VRow>
            }
          </VCardText>
        </VCard>
      );
    },

    cancelDialog() {
      if (this.showCancelDialog) {
        return (
          <CancelDialog
            bets={[this.bet]}
            returnBetFields={betQueryFields}
            onDisagree={() => this.showCancelDialog = false}
            onAgree={this.onBetCancelled}>
          </CancelDialog >
        );
      }
    },

    reconfirmDialog() {
      if (this.showReconfirmDialog) {
        return (
          <ReconfirmDialog
            bets={[this.bet]}
            returnBetFields={betQueryFields}
            onDisagree={() => this.showCancelDialog = false}
            onAgree={this.onBetCancelled}>
          </ReconfirmDialog >
        );
      }
    },

    pageTitle() {
      if (!this.bet?.isCashoutBet) {
        return 'Sports Bet';
      } else {
        return 'Sports Bet Cashout';
      }
    },
  },

  apollo: {
    betQuery: {
      query: BET_QUERY,

      variables(): { uuid: string } {
        return {
          uuid: this.betUUID,
        };
      },

      update(data) {
        this.bet = {
          ...data.sportsBet,
          translations: Object.fromEntries(data.sportsBet.translations),
        };
      },

      fetchPolicy: 'network-only',
    },

    betStateHistoryQuery: {
      query: BET_STATE_HISTORY_QUERY,

      variables(): { uuid: string } {
        return {
          uuid: this.bet.uuid,
        };
      },

      update(data) {
        this.betStateHistoryList = data.betStateHistoryList.list;
      },

      fetchPolicy: 'network-only',

      skip(): boolean {
        return !this.bet.uuid;
      },
    },

    providerBetsQuery: {
      query: PROVIDER_BETS_QUERY,

      variables(): { uuid: string } {
        return {
          uuid: this.bet.uuid,
        };
      },

      update(data) {
        return data.providerBets.bets;
      },


      fetchPolicy: 'network-only',

      skip(): boolean {
        return !this.bet.uuid;
      },
    },

    ledgerQuery: {
      query: LEDGER_QUERY,

      variables(): { betId: number } {
        return {
          betId: this.bet.id,
        };
      },

      update(data) {
        this.ledgerRecords = data.sportsBetLedgerRecords;
      },

      fetchPolicy: 'network-only',

      skip(): boolean {
        return !financePermitted() || !this.bet.id;
      },
    },
  },

  render() {
    if (this.$apollo.queries.betQuery.loading) {
      return (
        <VSkeletonLoader
          type='card-heading, image, card-heading, table-row-divider@2, card-heading, table-row-divider@2' />
      );
    }

    return (
      <div class='bet-details'>
        {this.titleCard}
        {this.failureSnackbar}
        <VCard elevation={3} class='mb-1'>
          <VCardTitle>Details</VCardTitle>
          <VCardText>
            <VRow dense>
              <VCol md='1' cols='4' class='text-right'>
                <strong class='text-uppercase'>Id</strong>
              </VCol>
              <VCol md='1' cols='8'>{this.bet.id}&nbsp;
                {this.bet.isCashoutBet &&
                  <VTooltip right color='black' scopedSlots={{
                    activator: ({ on }: { on: any }) => {
                      return <VIcon {...{ on }}>monetization_on</VIcon>;
                    },
                  }}>
                    <span>Cashout</span>
                  </VTooltip>}
              </VCol>
              <VCol md='1' cols='4' class='text-right'>
                <strong class='text-uppercase'>Brand</strong>
              </VCol>
              <VCol md='1' cols='8'>{this.bet.brand}</VCol>
              <VCol md='1' cols='4' class='text-right'>
                <strong class='text-uppercase'>Uuid</strong>
              </VCol>
              <VCol md='3' cols='8'>{this.bet.uuid}</VCol>
              <VCol md='2' cols='4' class='text-right'>
                <strong class='text-uppercase'>Created At</strong>
              </VCol>
              <VCol md='2' cols='8'>{unixToDateTimeUTCString(this.bet.createdAt)}</VCol>
            </VRow>
            <VRow dense>
              <VCol md='1' cols='4' class='text-right'>
                <strong class='text-uppercase'>State</strong>
              </VCol>
              <VCol md='1' cols='8'>{this.bet.state}</VCol>
              <VCol md='1' cols='4' class='text-right'>
                <strong class='text-uppercase'>Channel</strong>
              </VCol>
              <VCol md='1' cols='8'>{this.bet.channel}</VCol>
              <VCol md='1' cols='4' class='text-right'>
                <strong class='text-uppercase'>Bet slip</strong>
              </VCol>
              <VCol md='3' cols='8'>{this.bet.betSlipId}</VCol>
              <VCol md='2' cols='4' class='text-right'>
                <strong class='text-uppercase'>Completed/Cancelled At</strong>
              </VCol>
              <VCol md='2' cols='8'>
                {this.bet.cancelledAt !== 0 && unixToDateTimeUTCString(this.bet.cancelledAt) ||
                  this.bet.completedAt !== 0 && unixToDateTimeUTCString(this.bet.completedAt)}
              </VCol>
            </VRow>
            <VRow dense>
              <VCol md='1' cols='4' class='text-right'>
                <strong class='text-uppercase'>Stake</strong>
              </VCol>
              <VCol md='1' cols='8'>{this.stake}</VCol>
              <VCol md='1' cols='4' class='text-right'>
                <strong class='text-uppercase'>Result</strong>
              </VCol>
              <VCol md='1' cols='8'>{this.bet.result}</VCol>
              <VCol md='1' cols='4' class='text-right'>
                <strong class='text-uppercase'>Revenue</strong>
              </VCol>
              <VCol md='3' cols='8'>{this.revenue}</VCol>
              <VCol md='2' cols='4' class='text-right'>
                <strong class='text-uppercase'>{this.returnAmountLabel}</strong>
              </VCol>
              <VCol md='1' cols='8'>{this.returnAmount}</VCol>
            </VRow>
            <VRow dense>
              <VCol md='1' cols='4' class='text-right'>
                <strong class='text-uppercase'>SP</strong>
              </VCol>
              <VCol md='1' cols='8'>{this.bet.spendingPoints || '-'}</VCol>
              <VCol md='1' cols='4' class='text-right'>
                <strong class='text-uppercase'>Country</strong>
              </VCol>
              <VCol md='1' cols='8'>
                {
                  this.bet.country &&
                  <div>
                    <CountryFlag country={this.bet.country} size='normal' rounded={true} />&nbsp;
                    <span>{this.bet.country}</span>
                  </div>
                }
              </VCol>
              <VCol md='1' cols='4' class='text-right'>
                <strong class='text-uppercase'>Regraded by</strong>
              </VCol>
              <VCol md='3' cols='8'>{this.bet.regradedByAdminEmail || '-'}</VCol>
              <VCol md='2' cols='4' class='text-right'>
                <strong class='text-uppercase'>Regraded at</strong>
              </VCol>
              <VCol md='2' cols='8'>
                {this.bet.regradedByAdminAt === 0 ? '-' : unixToDateTimeUTCString(this.bet.regradedByAdminAt)}
              </VCol>
            </VRow>
            <VRow dense>
              <VCol md='1' cols='4' class='text-right'>
                <strong class='text-uppercase'>Comment</strong>
              </VCol>
              <VCol md='11' cols='8'>{this.bet.comment || '-'}</VCol>
            </VRow>
          </VCardText>
        </VCard>
        {this.cashoutRefs}
        {this.selections}
        {this.betStateHistory}
        {this.providerBets}
        {financePermitted() && this.walletTransactions}
        {this.cancelDialog}
        {this.reconfirmDialog}
        {Object.values<string>(Provider).map((p) => this.logsView(p))}
      </div>
    );
  },
});
