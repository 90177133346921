import { gql } from 'apollo-boost';

const ledgerQueryFields =
  `amount
  creditAccountType
  currency
  timestamp
  txEvent
  txId`;

interface LedgerRecord {
  amount: number;
  creditAccountType: string;
  currency: string;
  timestamp: string;
  txEvent: string;
  txId: string;
}

interface BetStateHistory {
  gradedBy: string;
  returnAmount: string;
  result: string;
  state: string;
  timestamp: string;
}

interface CashoutRef {
  exhaustedPotentialReturnAmount: number;
  uuid: string;
}

const betQueryFields =
  `betSlipId
  bonusStake
  bonusReturnAmount
  brand
  cancelledAt
  cashedOutBets {
    exhaustedPotentialReturnAmount
    uuid
  }
  cashouts {
    exhaustedPotentialReturnAmount
    uuid
  }
  channel
  comment
  completedAt
  country
  createdAt
  currency
  device
  exchangeRate
  exhaustedPotentialReturnAmount
  id
  isCashoutBet
  isMultiple
  manuallyRegradable
  playerUUID
  potentialReturnAmount
  regradedByAdminAt
  regradedByAdminEmail
  result
  returnAmount
  selections {
    externalProvider
    hedgingProviders
  }
  spendingPoints
  stake
  state
  transitions {
    mayCancel
    mayConfirm
  }
  translations
  userAgent
  uuid`;


const selectionsQueryFields =
  `
  id
  manuallyRegradable
  selections {
    adjustedPlacedOdds
    closingPrice
    event {
      competition {
        key
        name
      }
      competitors {
        key
        name
      }
      name
      players {
        key
        name
        position {
          key
          name
        }
        team
      }
      startsAt
    }
    eventId
    externalProvider
    externalId
    hedgingProviders
    id
    isLive
    marketId
    marketKey
    metadata
    name
    odds
    outright {
      competition {
        key
        name
      }
      finishesAt
      name
      players {
        key
        name
        position {
          key
          name
        }
        team
      }
    }
    result
    specialBetValue
    sportKey
  }
  state
  uuid`;

interface SportsBetSelection {
  adjustedPlacedOdds: number;
  closingPrice: number;
  event: {
    competition: {
      key: string;
      name: string;
    };
    competitors: Array<{
      key: string;
      name: string;
    }>;
    name: string;
    players: Array<{
      key: string;
      name: string;
      team: string;
      position: {
        key: string;
        name: string;
      }
    }>
    startsAt: number;
  };
  eventId: number;
  externalId: string;
  externalProvider: string;
  hedgingProviders: string;
  id: number;
  isLive: boolean;
  marketId: number;
  marketKey: string;
  metadata: string;
  name: string;
  odds: number;
  outright: {
    competition: {
      key: string;
      name: string;
    };
    finishesAt: number;
    name: string;
    players: Array<{
      key: string;
      name: string;
      team: string;
      position: {
        key: string;
        name: string;
      }
    }>
  };
  result: string;
  specialBetValue: string;
  sportKey: string;
}

interface SportsBet {
  bonusStake: number;
  bonusReturnAmount: number;
  brand: string;
  betSlipId: string;
  cancelledAt: number;
  cashedOutBets: CashoutRef[];
  cashouts: CashoutRef[];
  channel: string;
  comment: string;
  completedAt: number;
  country: string;
  createdAt: number;
  currency: string;
  device: string;
  exchangeRate: number;
  exhaustedPotentialReturnAmount: number;
  id: number;
  isCashoutBet: boolean;
  isMultiple: boolean;
  manuallyRegradable: boolean;
  playerUUID: string;
  potentialReturnAmount: number;
  regradedByAdminAt: number;
  regradedByAdminEmail: string;
  result: string;
  returnAmount: number;
  selections: Array<{ externalProvider: string, hedgingProviders: string }>;
  spendingPoints: number;
  stake: number;
  state: string;
  transitions: {
    mayCancel: boolean;
    mayConfirm: boolean;
  };
  translations: Record<string, string>;
  userAgent: string;
  uuid: string;
}

interface SportsBetSelections {
  id: number;
  manuallyRegradable: boolean;
  selections: SportsBetSelection[];
  state: string;
  uuid: string;
}

interface ProviderBet {
  betslipId: string;
  betType: string;
  betUuid: string;
  closingOdds?: string;
  completeTime?: string;
  createTime?: string;
  currency: string;
  customerId: string;
  exchangeRate: string;
  exhaustedPotentialReturnAmount?: string;
  internalBetUuid: string;
  isCashoutBet: boolean;
  placedExchangeRate: string;
  placedOdds: string;
  potentialReturnAmount: string;
  quoteCurrency: string;
  result: string;
  returnAmount?: string;
  selections: ProviderBetSelection[];
  settledOdds?: string;
  stake: string;
  state: string;
  updateTime?: string;
}

interface ProviderBetSelection {
  adjustedPlacedOdds?: string;
  cancellationReason?: string;
  cashedOutSelectionOdds?: string;
  closingOdds?: string;
  eventId: string;
  marketKey: string;
  numberOfPayouts?: number;
  numberOfTieWinners?: number;
  odds: string;
  outcome: string;
  params: string;
  result: string;
  voidfactor?: string;
  winfactor?: string;
}

const BET_QUERY = gql`query ($uuid: String!) {
  sportsBet(uuid: $uuid) {
    ${betQueryFields}
  }
}`;

const BET_SELECTIONS_QUERY = gql`query ($uuid: String!) {
  sportsBet(uuid: $uuid) {
    ${selectionsQueryFields}
  }
}`;

const LEDGER_QUERY = gql`query($betId: Int!) {
  sportsBetLedgerRecords(betId: $betId) {
    ${ledgerQueryFields}
  }
}`;

const BET_STATE_HISTORY_QUERY = gql`query Query($uuid: String!) {
  betStateHistoryList(betUuid: $uuid) {
    list {
      gradedBy
      returnAmount
      result
      state
      timestamp
    }
  }
}`;

const PROVIDER_BETS_QUERY = gql`query Query($uuid: String!) {
  providerBets(betUuid: $uuid) {
    bets {
      betslipId
      betType
      betUuid
      closingOdds
      completeTime
      createTime
      currency
      customerId
      exchangeRate
      exhaustedPotentialReturnAmount
      internalBetUuid
      isCashoutBet
      placedExchangeRate
      placedOdds
      potentialReturnAmount
      quoteCurrency
      result
      returnAmount
      selections {
        adjustedPlacedOdds
        cancellationReason
        cashedOutSelectionOdds
        closingOdds
        eventId
        marketKey
        numberOfPayouts
        numberOfTieWinners
        odds
        outcome
        params
        result
        voidfactor
        winfactor
      }
      settledOdds
      stake
      state
      updateTime
    }
  }
}`;


export {
  betQueryFields,
  BET_QUERY,
  BET_SELECTIONS_QUERY,
  LEDGER_QUERY,
  BET_STATE_HISTORY_QUERY,
  PROVIDER_BETS_QUERY,
  LedgerRecord,
  BetStateHistory,
  SportsBet,
  SportsBetSelection,
  SportsBetSelections,
  CashoutRef,
  ProviderBet,
};
